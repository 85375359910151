import { graphql, useStaticQuery } from 'gatsby';
import {
    KontentMedia,
    KontentRadioMultiChoice,
    KontentStringValue,
} from '../../../../types/KontentProps';

export interface BlogMetadata {
    id: number;
    elements: {
        title: KontentStringValue;
        short_description: KontentStringValue;
        explore_all_blogs_image: KontentMedia;
        blog_scroll_image: KontentMedia;
    };
}

type NoFollowChoices = 'yes' | 'no';

export interface BlogPagePreview {
    id?: string;
    preferred_language: string;
    elements: {
        blog_topic: KontentRadioMultiChoice<string>;
        wellness_type: KontentRadioMultiChoice<string>;
        slug: {
            value: string;
            url: string;
            web_spotlight: boolean;
        };
        other_metadata: { value: BlogMetadata[] };
        no_follow: KontentRadioMultiChoice<NoFollowChoices>;
        page_components: {
            value: {
                system: {
                    type: string;
                };
                readingTime?: {
                    minutes: number;
                    text: string;
                    time: number;
                    words: number;
                };
                elements?: {
                    display_publish_date?: KontentStringValue;
                    product_0acaef2?: {
                        value: [
                            {
                                elements: {
                                    slug: {
                                        value: string;
                                        url: string;
                                    };
                                };
                            }
                        ];
                    };
                };
            }[];
        };
    };
}

export interface BlogPageQuery {
    allKontentItemLayoutMasterPage: {
        nodes: BlogPagePreview[];
    };
}

export const useBlogs = (prefLanguage?: string): BlogPagePreview[] => {
    const blogPagePreviews: BlogPageQuery = useStaticQuery(graphql`
        {
            allKontentItemLayoutMasterPage(
                filter: {
                    elements: {
                        page_type: {
                            value: { elemMatch: { codename: { eq: "blog" } } }
                        }
                    }
                    system: { workflow_step: { ne: "archived" } }
                }
                sort: { system: { last_modified: DESC } }
            ) {
                nodes {
                    ...blogPagePreview
                }
            }
        }
    `);

    const sameLanguageBlogs =
        blogPagePreviews.allKontentItemLayoutMasterPage.nodes.filter(
            (blog) => blog.preferred_language === prefLanguage
        );

    const sortedBlogs = sameLanguageBlogs.sort((leftNode, rightNode) => {
        const getPublishDate = (node: BlogPagePreview) => {
            const mastheadComponent =
                node.elements?.page_components?.value?.filter(
                    (component) =>
                        component?.system?.type === 'component___blog_masthead'
                )[0];

            return mastheadComponent?.elements?.display_publish_date?.value;
        };

        let leftPublishDate = getPublishDate(leftNode);

        let rightPublishDate = getPublishDate(rightNode);

        if (!leftPublishDate) {
            return 1;
        }

        if (rightPublishDate && rightPublishDate > leftPublishDate) {
            return 1;
        }

        return -1;
    });

    return sortedBlogs;
};
